import "./commission.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useAddCommission } from "../../api/transactions/use-add-commission.js";
import Alert from "@mui/joy/Alert";
import UserSelectList from "../../components/user-select-list/user-select-list.js";
import { useAddPersonalizedCommission } from "../../api/transactions/use-add-personalized-commission.js";
function Commission() {
  const [commissionAmount, setCommissionAmount] = useState(0);
  const [defaultDate, setDefaultDate] = useState("");
  const [selectAll, setSelectAll] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleDataFromChild = (data, selectAll) => {
    setSelectedUsers(data);
    setSelectAll(selectAll);
  };
  const { mutate: addCommission } = useAddCommission({
    callBackOnSuccess: () => {
      toast.success("commission registered");
    },
  });

  const { mutate: addPersonalizedCommission } = useAddPersonalizedCommission({
    callBackOnSuccess: () => {
      toast.success("commission registered");
    },
  });
  useEffect(() => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const formattedDate = oneMonthAgo.toISOString().split("T")[0];
    setDefaultDate(formattedDate);
  }, []);
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">Commisison </div>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            width: "fit-content",
            gap: "10px",
            alignItems: "stretch",
            padding: "20px",
            margin: "auto",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
          onSubmit={(event) => {
            event.preventDefault();
            if (
              window.confirm(
                "Please confirm that there are no accounts awaiting KYC approval"
              )
            ) {
              const formData = new FormData(event.target);
              if (!selectAll && selectedUsers.length === 0) {
                toast.error("Select users to apply commission to");
              } else if (selectAll) {
                addCommission(formData);
              } else {
                selectedUsers.forEach((id) =>
                  formData.append("comm_users", id)
                );
                addPersonalizedCommission(formData);
              }
            }
          }}
        >
          <div>Commission value</div>
          {commissionAmount >= 0 && commissionAmount <= 1 ? (
            <Alert variant="soft" color="primary">
              {" "}
              The commission is placed at a value of {commissionAmount * 100}%
            </Alert>
          ) : (
            <Alert variant="soft" color="danger">
              Invalid commission amount
            </Alert>
          )}
          <Input
            placeholder=""
            value={commissionAmount}
            onChange={(e) => setCommissionAmount(e.target.value)}
            variant="soft"
            name="comm_amt"
          />

          <div>Commission date</div>
          <input
            aria-label="Date"
            type="date"
            name="comm_date"
            value={defaultDate}
          />

          <div>Users to give commission to</div>
          <UserSelectList sendDataToParent={handleDataFromChild} />
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </div>
  );
}

export default Commission;
