import "./kyc-approve.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import React, { useState } from "react";
import Link from "@mui/joy/Link";
import DateTimeDisplay from "../../components/date-time-display.js";
import toast from "react-hot-toast";
import { useEditMemberList } from "../../api/members-list/use-edit-member-list.js";
import { useListKYCUsers } from "../../api/users/use-list-kyc-users.js";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";


function KYCApprove() {
  const [userID, setUserID] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [reasonForReject, setReasonForReject] = React.useState("");
  const [filterUserName, setFilterUserName] = useState("");
  const { mutate: updateUser } = useEditMemberList({
    id: userID,
  });
  const handleApprove = async (pk) => {
    setUserID(pk);
    updateUser({
      profile: {
        kyc_approved: "APPROVED",
      },
    });
  };

  const handleReject = async () => {
    // setUserID(pk);
    updateUser({
      profile: {
        kyc_approved: "REJECTED",
      },
      rejection_reason: reasonForReject,
    });
  };
  const { data: users } = useListKYCUsers();
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">KYC registration requests</div>
        <Stack spacing={1} direction="row" style={{ marginBottom: "10px" }}>
          <Input
            placeholder="Username"
            value={filterUserName}
            onChange={(e) => setFilterUserName(e.target.value)}
          />
          <Button
            onClick={() => {
              setFilterUserName("");
              toast.success("Filters cleared");
            }}
          >
            Reset
          </Button>
        </Stack>

        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="manually-styled-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Gender</th>
                <th>Date of birth</th>
                <th>Email</th>
                <th>Country</th>
                <th>Phone</th>
                <th>Bank details</th>
                <th>ID picture</th>
                <th>Passport picture</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users?.map((row) => (
                <tr key={row.id}>
                  <td>
                    <DateTimeDisplay apiDateTime={row.date_joined} />
                  </td>
                  <td>{row.username} </td>
                  <td>{row.first_name} </td>
                  <td>{row.last_name} </td>
                  <td>{row.profile.gender}</td>
                  <td>{row.profile.date_of_birth}</td>
                  <td>{row.email}</td>
                  <td>{row.profile.country}</td>
                  <td>{row.profile.phone_number}</td>
                  <td>
                    Branch:{row.profile.bank_branch} <br />
                    Name: {row.profile.bank_name} <br />
                    IBAN: {row.profile.bank_iban}
                    <br />
                    Swift code: {row.profile.bank_swiftcode}
                    <br />
                  </td>
                  <td>
                    {row.profile.id_picture ? (
                      <Link
                        level="body-sm"
                        href={row.profile.id_picture}
                        target="_blank"
                      >
                        {" "}
                        ID picture
                      </Link>
                    ) : (
                      "no file provided"
                    )}
                  </td>
                  <td>
                    {row.profile.passport_picture ? (
                      <Link
                        level="body-sm"
                        href={row.profile.passport_picture}
                        target="_blank"
                      >
                        {" "}
                        Passport picture
                      </Link>
                    ) : (
                      "no file provided"
                    )}
                  </td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={() => {
                          setUserID(row.id);
                          handleApprove(row.id);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        size="sm"
                        variant="soft"
                        color="danger"
                        onClick={() => {
                          setUserID(row.id);
                          setOpen(true);
                          // handleReject(row.id);
                        }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Reject user application?
          </Typography>
            Please input the reason for this rejection
            <Input
              placeholder="Type in here…"
              onChange={(e) => {
                setReasonForReject(e.target.value);
              }}
            />
            <br/>
            <Box sx={{ display: "flex", gap: 1 , justifyContent:"space-between"}}>
              <Button
                size="sm"
                variant="soft"
                color="primary"
                onClick={() => {
                  setOpen(false);
                  setReasonForReject("");
                }}
              >
                Cancel
              </Button>
              <Button
                size="sm"
                variant="soft"
                color="danger"
                onClick={() => {
                  setOpen(false);
                  handleReject();
                }}
              >
                Reject
              </Button>
            </Box>
        </Sheet>
      </Modal>
    </div>
  );
}

export default KYCApprove;
