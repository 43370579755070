import "./approve.css";
import SearchBar from "../../components/search-bar/search-bar.js";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Input from "@mui/joy/Input";
import Stack from "@mui/joy/Stack";
import React, { useState } from "react";
import DateTimeDisplay from "../../components/date-time-display.js";
import { useListUsers } from "../../api/users/use-list-users.js";
import toast from "react-hot-toast";
import { useEditTransaction } from "../../api/transactions/use-edit-transaction.js";
import { useEditMemberList } from "../../api/members-list/use-edit-member-list.js";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
function areEqual(num1, num2) {
  return num1 === num2;
}

function Approve() {
  const [userID, setUserID] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [reasonForReject, setReasonForReject] = React.useState("");
  const [transactionID, setTransactionID] = useState(0);
  const [filterUserName, setFilterUserName] = useState("");

  const { mutate: updateTransaction, isPending } = useEditTransaction({
    id: transactionID,
    callBackOnSuccess: () => {},
  });
  const { mutate: updateUser, isPending2 } = useEditMemberList({
    id: userID,
  });
  const handleApprove = async (pk, owner) => {
    setUserID(owner);
    // updateTransaction({ trans_status: "APPROVED" });
    updateUser({
      profile: {
        profile_active: "APPROVED",
      },
    });
  };
  const handleReject = async () => {
    // updateTransaction({ trans_status: "REJECTED" });
    updateUser({
      profile: {
        profile_active: "REJECTED",
      },
      rejection_reason: reasonForReject,
    });
  };

  const { data: users } = useListUsers();
  return (
    <div className="main-content">
      <SearchBar styleName="desktop-search" />
      <div className="main-overview">
        <div className="mid-title">Profile registration requests</div>
        <Stack spacing={1} direction="row" style={{ marginBottom: "10px" }}>
          <Input
            placeholder="Username"
            value={filterUserName}
            onChange={(e) => setFilterUserName(e.target.value)}
          />
          <Button
            onClick={() => {
              setFilterUserName("");
              toast.success("Filters cleared");
            }}
          >
            Reset
          </Button>
        </Stack>

        <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="manually-styled-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Username</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Payment amount (USD)</th>
                <th>Payment amount (BTC)</th>
                <th>Transaction type</th>
                <th>E-wallet balance</th>
                <th
                  aria-label="last"
                  style={{ width: "var(--Table-lastColumnWidth)" }}
                />
              </tr>
            </thead>
            <tbody>
              {users?.map((row) => (
                <tr key={row.pk}>
                  <td>
                    <DateTimeDisplay apiDateTime={row.trans_date} />
                  </td>
                  <td>{row.username} </td>
                  <td>{row.first_name} </td>
                  <td>{row.last_name} </td>
                  <td>{row.trans_amt_usd} USD</td>
                  <td>{row.trans_amt_btc} BTC</td>
                  <td>{row.trans_mode}</td>
                  <td>{row.balance}</td>
                  <td>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Button
                        size="sm"
                        variant="soft"
                        color="neutral"
                        onClick={() => {
                          setUserID(row.owner);
                          handleApprove(row.id, row.owner);
                        }}
                      >
                        Approve
                      </Button>
                      <Button
                        size="sm"
                        variant="soft"
                        color="danger"
                        onClick={() => {
                          setUserID(row.owner);
                          setOpen(true);
                        }}
                      >
                        Reject
                      </Button>
                    </Box>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: "md", p: 3, boxShadow: "lg" }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: "lg", mb: 1 }}
          >
            Reject user application?
          </Typography>
          Please input the reason for this rejection
          <Input
            placeholder="Type in here…"
            onChange={(e) => {
              setReasonForReject(e.target.value);
            }}
          />
          <br />
          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "space-between" }}
          >
            <Button
              size="sm"
              variant="soft"
              color="primary"
              onClick={() => {
                setOpen(false);
                setReasonForReject("");
              }}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="soft"
              color="danger"
              onClick={() => {
                setOpen(false);
                handleReject();
              }}
            >
              Reject
            </Button>
          </Box>
        </Sheet>
      </Modal>
    </div>
  );
}

export default Approve;
