import './monthly-return.css';
import SearchBar from '../../components/search-bar/search-bar.js';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Input from '@mui/joy/Input';
import Stack from '@mui/joy/Stack';
import React, { useState } from 'react';
import DateTimeDisplay from '../../components/date-time-display.js';
import { useListMonthlyReturn } from '../../api/transactions/use-list-monthly-transactions.js';
import toast from 'react-hot-toast';


function MonthlyReturn(){

    const [filterUserName, setFilterUserName] = useState("");
    const [filterPaymentGateway, setFilterPaymentGateway] = useState("");


    const { data: returns } = useListMonthlyReturn();
    const filteredReturns = returns?.filter((row) => {
        const matchesUserName = row.owner ===filterUserName.toLowerCase();
        return matchesUserName 
    })
    return(
        <div className="main-content">
          
        <SearchBar styleName="desktop-search"/>
        <div className="main-overview">
        <div className="mid-title">Monthly return </div>
            {/* <Stack spacing={1} direction="row" style={{marginBottom:"10px"

            }}>
                <Input placeholder="Username" value={filterUserName} 
                    onChange={(e) => setFilterUserName(e.target.value)}/>
                <Input placeholder="payment gateway" value={filterPaymentGateway} 
                    onChange={(e) => setFilterPaymentGateway(e.target.value)}/>
                <Button 
              onClick={() => {
                setFilterUserName("");
                setFilterPaymentGateway("");
                toast.success("Filters cleared");
              }}>Reset</Button>
            </Stack> */}
        
            <div style={{ maxWidth: "100%", overflowX: "auto" }}>
          <table className="manually-styled-table">
                <thead>
                    <tr>
                    <th>Date</th>
                    <th>Commission %</th>
                    <th>Total commission (USD)</th>
                    <th>Total commission (BTC)</th>
                    <th>Total investment (USD)</th>
                    <th>Total investment (BTC)</th>
                    <th>Total payout (USD)</th>
                    <th>Total payout (BTC)</th>
                    <th>Total registration (USD)</th>
                    <th>Total registration (BTC)</th>
                    <th>Total reinjection (USD)</th>
                    <th>Total reinjection (BTC)</th>
                    </tr>
                </thead>
                <tbody>
                    {returns?.map((row) => (
                    <tr key={row.date}>
                        <td>{row.date}</td>
                        <td>{row.comm_amt*100}%</td>
                        <td>{row.sum_commission_usd} USD</td>
                        <td>{row.sum_commission_btc}BTC</td>
                        <td>{row.sum_investment_usd} USD</td>
                        <td>{row.sum_investment_btc}BTC</td>
                        <td>{row.sum_payout_usd} USD</td>
                        <td>{row.sum_payout_btc}BTC</td>
                        <td>{row.sum_registration_usd} USD</td>
                        <td>{row.sum_registration_btc}BTC</td>
                        <td>{row.sum_reinjection_usd} USD</td>
                        <td>{row.sum_reinjection_btc}BTC</td>
                    </tr>
                    ))}
                </tbody>
                </table>
            </div>
        
        </div>
      </div>
    );
};


export default MonthlyReturn;